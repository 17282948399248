import React from "react";

const MobileModalContainer = (props) => {
  const { children } = props;

  return (
    <div className="flex flex-col justify-between items-center lg:hidden h-[calc(100dvh)]">
      {children}
    </div>
  )
}

export default MobileModalContainer;