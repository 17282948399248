import React from "react";

const DesktopModalContainer = (props) => {
  const { children } = props;

  return (
    <div className="hidden lg:block">
      <div className="flex h-screen w-full items-center justify-center">
        <div className="w-[480px] min-h-[670px] desktop-modal">
          {children}
        </div>
      </div>
    </div>
  )
}

export default DesktopModalContainer;